import Swiper from 'swiper/bundle'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/swiper-bundle.css'
import 'alpinejs'


window.addEventListener('load', () => {
    const experts = new Swiper('.experts-container', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.experts-next',
            prevEl: '.experts-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 30,
            },
        },
        pagination: {
            el: '.experts-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: 'pagination-bullet',
            bulletActiveClass: 'is-active',
        },
    })

    const quotes = new Swiper('.quotes-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        navigation: {
            nextEl: '.quotes-next',
            prevEl: '.quotes-prev',
            disabledClass: 'is-invisible',
        },
        pagination: {
            el: '.quotes-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: 'pagination-bullet',
            bulletActiveClass: 'is-active',
        },
    })

    document.querySelectorAll('[data-src]').forEach(image => {
        const src = image.dataset.src
        image.setAttribute('src', src)
    })

    AOS.init()
})


